import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {TranslationService} from '../../services/translation.service';
import {NotificationEditorService} from './notification-editor.service';
import {NotificationComponent, NotificationContent, NotificationEntry} from './notificationEntry';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SUCCESS_SNACK_CONFIG} from '../../../utils';
import {MatDialog} from '@angular/material/dialog';
import {EditNotificationDialog} from './edit-notification-dialog/edit-notification-dialog.component';

@Component({
  selector: 'vit-notification-editor',
  templateUrl: './notification-editor.component.html',
  styleUrls: ['./notification-editor.component.scss']
})
export class NotificationEditorComponent implements OnInit {

  selectedNotification?: NotificationEntry;
  filteredNotifications: NotificationEntry[] = [];
  notifications: NotificationEntry[] = [];
  searchValue = '';

  constructor(public sessionService: SessionService,
              public snack: MatSnackBar,
              public translate: TranslationService,
              public dialog: MatDialog,
              public notificationEditorService: NotificationEditorService) {
  }

  filterChange() {
    if (!this.searchValue) {
      this.filteredNotifications = this.notifications;
      return;
    }
    this.filteredNotifications = this.notifications.filter(n => {
      return JSON.stringify(n).toLowerCase().includes(this.searchValue.toLowerCase());
    });
  }

  ngOnInit() {
    this.notificationEditorService.fetchInbox().subscribe(inbox => {
      this.notifications = inbox;
      this.filteredNotifications = inbox;
      this.searchValue = '';
    });
  }

  open(notification: NotificationEntry) {
    this.selectedNotification = notification;
  }

  visitLink(notification: NotificationEntry, linkId: number) {
    this.snack.open('visit: ' + linkId, null, SUCCESS_SNACK_CONFIG);

  }

  getText(content: NotificationContent[]) {
    const localizedContent = content?.find(c => c.language === this.translate.getLanguage());
    const germanContent = content?.find(c => c.language === 'de');
    return (localizedContent || germanContent)?.value;
  }

  linkInternal(notification: NotificationEntry, component: NotificationComponent) {
    this.snack.open(component.target + ': ' + component.data, null, SUCCESS_SNACK_CONFIG);
  }

  submit(notification: NotificationEntry) {
    const feedback = notification.body.components
      .filter(n => n.componentType === 'question')
      .map(n => {
        return {
          'questionId': n.id,
          'answerKeys': n.type === 'checkbox' ? n.answers.filter(a => a.selected).map(a => a.key)
            : n.selectedKey ? [n.selectedKey] : [],
          'feedback': n.type === 'feedback' ? n.feedbackValue : undefined
        };
      });
    this.snack.open('submit: ' + feedback, null, SUCCESS_SNACK_CONFIG);
  }

  readyToSubmit(notifcation: NotificationEntry) {
    return notifcation.body.components
      .filter(n => n.componentType === 'question')
      .every(n => !n.required ||
        ((n.type === 'checkbox' && n.answers.filter(a => a.selected).length > 0)
          || (['radio', 'radio_horizontal'].indexOf(n.type) > -1 && !!n.selectedKey)
          || (n.type === 'feedback' && !!n.feedbackValue)));
  }

  editNotification(notification?: NotificationEntry) {
    this.dialog.open(EditNotificationDialog, {
      data: notification ? JSON.parse(JSON.stringify(notification)) : undefined,
    });
  }
}
