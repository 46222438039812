<div class="navbar">
  <mat-form-field appearance="fill" class="search-form-field">
    <mat-label>Filter</mat-label>
    <input [(ngModel)]="searchValue" (ngModelChange)="filterChange()" matInput #inp
           placeholder="Suchbegriff oder ID eingeben">
  </mat-form-field>
  <button mat-icon-button (click)="searchValue = ''; filterChange()">
    <mat-icon>close</mat-icon>
  </button>
  <button mat-raised-button color="accent" (click)="editNotification()">Erstellen</button>
</div>
<p class="info-empty" *ngIf="filteredNotifications.length === 0">{{ 'Empty' }}</p>
<mat-accordion multi>
  <mat-expansion-panel *ngFor="let notification of filteredNotifications"
                       (opened)="open(notification)"
                       [expanded]="notification === selectedNotification">
    <mat-expansion-panel-header>
      <mat-panel-title style="display: flex; justify-content: space-between; align-items: center">
        <div style="display: flex; justify-content: flex-start; align-items: center">
          <button mat-raised-button color="accent" (click)="editNotification(notification); $event.stopPropagation()">Bearbeiten</button>&nbsp;&nbsp;
          <markdown [data]="getText(notification.subject)"></markdown>
        </div>
        <div style="text-align: right; opacity: 0.6;">
          <span>{{ notification.date | date: 'dd.MM.yy' }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngFor="let component of notification.body.components">
      <ng-container *ngIf="component.componentType === 'text'">
        <markdown [data]="getText(component.content)"></markdown>
      </ng-container>
      <ng-container *ngIf="component.componentType === 'link'">
        <a *ngIf="component.type === 'external'" [href]="component.url" (click)="visitLink(notification, component.id)">
          <markdown [data]="getText(component.text)"></markdown>
        </a>
        <p class="link-internal" *ngIf="component.type === 'internal'"
           (click)="linkInternal(notification, component)">{{ getText(component.text) }}</p>
        <button class="action-button" [disabled]="!readyToSubmit(notification)"
                *ngIf="component.type === 'submit'" (click)="submit(notification)">{{ getText(component.text) }}
        </button>
      </ng-container>
      <ng-container *ngIf="component.componentType === 'question'">
        <p>
          <markdown [data]="getText(component.text) + (component.required ? '*' : '')"></markdown>
        </p>
        <mat-radio-group *ngIf="component.type === 'radio_horizontal' || component.type === 'radio'"
                         [(ngModel)]="component.selectedKey"
                         [class.vertical]="component.type === 'radio'">
          <mat-radio-button [value]="answer.key"
                            *ngFor="let answer of component.answers">{{ getText(answer.text) }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="textarea-container" *ngIf="component.type === 'feedback'">
          <textarea cdkTextareaAutosize cdkAutosizeMinRows="4" [(ngModel)]="component.feedbackValue"
                    [maxLength]="component.maxChars || 200"></textarea>
          <span class="char-count">{{ component.feedbackValue?.length || 0 }}/{{ component.maxChars || 200 }}</span>
        </div>
        <section *ngIf="component.type === 'checkbox'">
          <mat-checkbox *ngFor="let answer of component.answers"
                        [(ngModel)]="answer.selected">{{ getText(answer.text) }}
          </mat-checkbox>
        </section>
      </ng-container>

      <ng-container
        *ngIf="component.componentType != 'text' && component.componentType != 'link' && component.componentType != 'question'">
        <p>{{ component | json }}</p>
      </ng-container>


    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
