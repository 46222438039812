import {Component, HostBinding, Inject, Optional, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {TranslationService} from '../../../services/translation.service';
import {SessionService} from '../../../services/session.service';
import {NotificationComponent} from '../notificationEntry';

@Component({
  selector: 'vit-edit-notification-dialog',
  templateUrl: './edit-notification-dialog.component.html',
  styleUrls: ['./edit-notification-dialog.component.scss']
})
export class EditNotificationDialog implements OnInit {

  constructor(public translationService: TranslationService,
              public sessionService: SessionService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: { notification?: NotificationComponent },
              public route: ActivatedRoute) {
  }

  ngOnInit() {
    console.log('data', this.data);
  }
}
