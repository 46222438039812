import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home.component';
import {HomeMenuPageguard} from './home-menu.pageguard';
import {TicketsComponent} from './tickets/tickets.component';
import {BalanceComponent} from './balance/balance.component';
import {BalanceMonthComponent} from './balance-month/balance-month.component';
import {CashbookComponent} from './cashbook/cashbook.component';
import {TerminalCashComponent} from './terminal-cash/terminal-cash.component';
import {TicketSearchComponent} from './ticket-search/ticket-search.component';
import {CashTicketOverviewComponent} from './cash-ticket-overview/cash-ticket-overview.component';
import {StationOverviewComponent} from './station-overview/station-overview.component';
import {TicketListStationsComponent} from './ticket-list-stations/ticket-list-stations.component';
import {HoldOverviewComponent} from './hold-overview/hold-overview.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {CurrenciesComponent} from './currencies/currencies.component';
import {GroupJackpotsComponent} from './group-jackpots/group-jackpots.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {RoleManagementComponent} from './role-management/role-management.component';
import {GroupManagementComponent} from './group-management/group-management.component';
import {JackpotOverviewComponent} from './jackpot-overview/jackpot-overview.component';
import {BalanceActualizationComponent} from './balance-actualization/balance-actualization.component';
import {ResultsRf2Component} from './results-rf2/results-rf2.component';
import {ResultsBb1Component} from './results-bb1/results-bb1.component';
import {ResultsPb1Component} from './results-pb1/results-pb1.component';
import {CycleBb1Component} from './cycle-bb1/cycle-bb1.component';
import {CyclePb1Component} from './cycle-pb1/cycle-pb1.component';
import {CycleRf2Component} from './cycle-rf2/cycle-rf2.component';
import {WebshopHoldOverviewComponent} from './webshop-hold-overview/webshop-hold-overview.component';
import {ThirdPartyBalanceComponent} from './third-party-balance/third-party-balance.component';
import {CashTimelineComponent} from './cash-timeline/cash-timeline.component';
import {CashbookControllingComponent} from './cashbook-controlling/cashbook-controlling.component';
import {UaTimelineComponent} from './ua-timeline/ua-timeline.component';
import {BalanceCashTotalComponent} from './balance-cash-total/balance-cash-total.component';
import {NumbersHoldOverviewComponent} from './numbers-hold-overview/numbers-hold-overview.component';
import {CustomerInfoComponent} from './customer-info/customer-info.component';
import {TurnoverComponent} from './turnover/turnover.component';
import {PokerHoldOverviewComponent} from './poker-hold-overview/poker-hold-overview.component';
import {LiveHoldOverviewComponent} from './live-hold-overview/live-hold-overview.component';
import {BalanceLivesportsComponent} from './balance-livesports/balance-livesports.component';
import {VirtualModeComponent} from './virtual-mode/virtual-mode.component';
import {VirtualModeLogoutComponent} from './virtual-mode-logout/virtual-mode-logout.component';
import {BetLimitsComponent} from './bet-limits/bet-limits.component';
import {BalanceFranchiserComponent} from './balance-franchiser/balance-franchiser.component';
import {BalanceGroupComponent} from './balance-group/balance-group.component';
import {HoldOverviewFranchiserComponent} from './hold-overview-franchiser/hold-overview-franchiser.component';
import {CustomerHoldComponent} from './customer-hold/customer-hold.component';
import {CustomerBalanceWeekComponent} from './customer-balance-week/customer-balance-week.component';
import {CashErrorsComponent} from './cash-errors/cash-errors.component';
import {RecyclerTimelineComponent} from './recycler-timeline/recycler-timeline.component';
import {NotificationEditorComponent} from './notification-editor/notification-editor.component';

const routes: Routes = [
  {
    path: 'home/balance', component: BalanceComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/balance-franchiser', component: BalanceFranchiserComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/balance-group', component: BalanceGroupComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/balance-livesports', component: BalanceLivesportsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/hold-overview-franchiser', component: HoldOverviewFranchiserComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/turnover', component: TurnoverComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/balance-month', component: BalanceMonthComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/virtual-mode', component: VirtualModeComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/virtual-mode-logout', component: VirtualModeLogoutComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/cashbook', component: CashbookComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/tickets', component: TicketsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/terminal-cash', component: TerminalCashComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/ticket-search', component: TicketSearchComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/ticket-search/:ticketnr', component: TicketSearchComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/cash-ticket-overview', component: CashTicketOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/cash-errors', component: CashErrorsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/station-overview', component: StationOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/ticket-list-stations', component: TicketListStationsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/hold-overview/:type', component: HoldOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/change-password', component: ChangePasswordComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/currencies', component: CurrenciesComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/jackpots', component: GroupJackpotsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/jackpot-overview', component: JackpotOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/balance-actualization', component: BalanceActualizationComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/user-management', component: UserManagementComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/role-management', component: RoleManagementComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/group-management', component: GroupManagementComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/:menu/:submenu', component: HomeComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/:menu/:game-number/:query', component: TicketsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home', component: HomeComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/smart-race-results', component: ResultsRf2Component, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/keno-bet-results', component: ResultsBb1Component, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/poker-bet-results', component: ResultsPb1Component, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/keno-bet-cycle', component: CycleBb1Component, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/poker-bet-cycle', component: CyclePb1Component, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/smart-race-cycle', component: CycleRf2Component, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/webshop-hold-overview', component: WebshopHoldOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/third-party-balances', component: ThirdPartyBalanceComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/cash-timeline', component: CashTimelineComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/recycler-timeline', component: RecyclerTimelineComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/cashbook-controlling', component: CashbookControllingComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/user-account-timeline', component: UaTimelineComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/bet-limits', component: BetLimitsComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/balance-cash-total', component: BalanceCashTotalComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/numbers-hold-overview', component: NumbersHoldOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/poker-hold-overview', component: PokerHoldOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/live-hold-overview', component: LiveHoldOverviewComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/customer-info', component: CustomerInfoComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/customer-hold', component: CustomerHoldComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/customer-balance-week', component: CustomerBalanceWeekComponent, canActivate: [HomeMenuPageguard]
  },
  {
    path: 'home/notification-editor', component: NotificationEditorComponent, canActivate: [HomeMenuPageguard]
  }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [HomeMenuPageguard]
})
export class HomeRoutingModule {
}
